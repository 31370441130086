<template>
  <layout-vertical>

    <router-view />

    <!-- Using navbar scoped slot -->
    <app-navbar-vertical-layout slot="navbar" :toggle-vertical-menu-active="toggleVerticalMenuActive"/>
    
    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

    <page-header slot="breadcrumb" />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import AppNavbarVerticalLayout from '../components/app-navbar/AppNavbarVerticalLayout.vue'
import PageHeader from '../components/PageHeader.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    AppNavbarVerticalLayout,
    PageHeader,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  setup() {

    const {
      navbarType, footerType,
    } = useAppConfig()
    
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
    } = useVerticalLayout(navbarType, footerType)

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
    }
  },
}
</script>
