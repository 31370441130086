<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
        </b-col>
      </b-row>
    </b-col>

    
    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-row >
      
      <b-col cols = "6" >
        <b-button
          variant="primary"
          class="btn-icon"
          v-b-toggle:[$route.meta.filters.sidebarId]
          v-if="$route.meta.filters"
        >
          <feather-icon icon="SlidersIcon" />
          {{ $t('Φίλτρα') }}
        </b-button>
      </b-col>

      <b-col cols = "4">
        <b-button
            class="btn-icon"
            variant="outline-primary"
            type="submit"
            v-if="$route.meta.refresh"
            @click = 'refreshOrders'
          >
            {{ $t('Ανανέωση') }}
          </b-button>
      </b-col>

      </b-row>

    </b-col>
  
    
  </b-row>
</template>

<script>
import {
  BRow, BCol, BDropdown, BDropdownItem, BButton, VBToggle
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    VBToggle,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods:{
    refreshOrders(){
      this.$root.$emit('refresh:button');      
    }
  }
}
</script>
