export default [
  // {
  //   header: 'MarketingCapital',
  // },
  // {
  //   title: 'Offers',
  //   route: 'marketing-offers',
  //   icon: 'TagIcon',
  // },
  // {
  //   title: 'Pricelists',
  //   route: 'marketing-pricelists',
  //   icon: 'DollarSignIcon',
  // },
]
