<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          <!-- {{ userData.displayName || userData.email }} -->
          {{dispName}}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
      >
        <feather-icon
          v-if="!userData.displayName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'user-details'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ $t('Προφίλ') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'company-info'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BriefcaseIcon"
        class="mr-50"
      />
      <span>{{ $t('Στοιχεία Εταιρείας') }}</span>
    </b-dropdown-item>

        <b-dropdown-item
      :to="{ name: 'shipping-info'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="PackageIcon"
        class="mr-50"
      />
      <span>{{ $t('Πληροφορίες Παράδοσης') }}</span>
    </b-dropdown-item>
<!--
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BriefcaseIcon"
        class="mr-50"
      />
      <span>{{ $t('companyDetails') }}</span>
    </b-dropdown-item>

    
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="TruckIcon"
        class="mr-50"
      />
      <span>{{ $t('Πληροφορίες παράδοσης') }}</span>
    </b-dropdown-item>
    -->
    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('Logout') }}</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: this.$store.state.user.userData,
      avatarText,
    }
  },
  computed:{
    dispName(){
      return this.$store.state.user.userData.firstname + ' ' + this.$store.state.user.userData.lastname
      },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout", this)
      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
