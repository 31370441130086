export default [
  {
    header: 'SalesCapital',
  },
  {
    title: 'Orders',
    route: 'sales-orders',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'CreditAgreements',
    route: 'sales-credit-terms',
    icon: 'UserCheckIcon', // 'ThumbsUpIcon',
  },
]
